import React from 'react';
import { Box, Col, Container, Link, Row } from '@typedbootstrap/ui-kit-pro-components';
import { AuthenticationTemplate } from '@typedbootstrap/ui-kit-web-components';
import {
    PasswordRecoveryForm,
    type TPasswordRecoveryFormSubmitHandler,
    type TPasswordRecoveryFormValues
} from 'components/organisms';
import { Text } from 'react-text-toolkit';

export type TRecoveryPasswordArgs = {
    email: string;
};

export type TRecoveryPassword = (args: TRecoveryPasswordArgs) => Promise<void>;

export interface ForgotPasswordPageProps {
    recoveryPassword: TRecoveryPassword;
}

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({ recoveryPassword }) => {
    const initialValues: TPasswordRecoveryFormValues = {
        email: ''
    };

    const onSubmit: TPasswordRecoveryFormSubmitHandler = async (
        { email },
        { resetForm, setSubmitting }
    ) => {
        try {
            await recoveryPassword({ email });

            resetForm();
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <AuthenticationTemplate>
            <Container>
                <Row>
                    <Col md="9" lg="8" className="mx-auto">
                        <h2 className="fw-light mb-3">
                            <Text textKey="components.pages.forgotPassword.title" />
                        </h2>
                        <Box className="lead" mb="3">
                            <Text textKey="components.pages.forgotPassword.message" />
                        </Box>
                        <PasswordRecoveryForm initialValues={initialValues} onSubmit={onSubmit} />
                        <Link className="d-block text-center mt-3 small" to="/login">
                            <Text textKey="components.pages.forgotPassword.links.login" />
                        </Link>
                    </Col>
                </Row>
            </Container>
        </AuthenticationTemplate>
    );
};

export default ForgotPasswordPage;
