import React from 'react';
import { DefaultLayout } from 'layouts';

const withDefaultLayout = <P extends object>(Component: React.ComponentType<P>) => {
    return (props: P) => (
        <DefaultLayout>
            <Component {...props} />
        </DefaultLayout>
    );
};

export default withDefaultLayout;
