import React from 'react';
import { Box, Col, Container, Link, Row } from '@typedbootstrap/ui-kit-pro-components';
import { ErrorTemplate } from '@typedbootstrap/ui-kit-web-components';
import { Text } from 'react-text-toolkit';

export interface NoMatchPageProps {}

const NoMatchPage: React.FC<NoMatchPageProps> = () => {
    return (
        <ErrorTemplate>
            <Container>
                <Row className="align-items-center justify-content-center min-vh-100">
                    <Col lg="6">
                        <Box className="text-center" mt="4">
                            <h2 className="fw-light mb-4">
                                <Text textKey="components.pages.noMatch.title" />
                            </h2>
                            <p className="mb-4">
                                <Text textKey="components.pages.noMatch.message" />
                            </p>
                            <Link to="/">
                                <Text textKey="components.pages.noMatch.links.home" />
                            </Link>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </ErrorTemplate>
    );
};

export default NoMatchPage;
