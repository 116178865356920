import React from 'react';
import { AuthProvider } from 'contexts';
import { DocumentMetaProvider } from 'react-document-metadata';
import { FeatureToggleProvider } from 'react-feature-toggler';
import { LinkProvider } from 'react-link-toolkit';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Link } from 'react-router-dom';
import { TextProvider } from 'react-text-toolkit';
import store from 'store';
import { featureToggles, texts } from 'utilities/constants';

export interface AppProvidersProps {
    children: React.ReactNode;
}

const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
    return (
        <DocumentMetaProvider>
            <TextProvider texts={texts}>
                <ReduxProvider store={store}>
                    <FeatureToggleProvider featureToggles={featureToggles}>
                        <AuthProvider>
                            <BrowserRouter>
                                <LinkProvider LinkComponent={Link}>{children}</LinkProvider>
                            </BrowserRouter>
                        </AuthProvider>
                    </FeatureToggleProvider>
                </ReduxProvider>
            </TextProvider>
        </DocumentMetaProvider>
    );
};

export default AppProviders;
