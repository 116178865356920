import React from 'react';
import { LoginPage, type TLogin } from 'components/pages';
import { useAuth } from 'hooks';
import { useDocumentTitle } from 'react-document-metadata';
import { useText } from 'react-text-toolkit';
import { toast } from 'react-toastify';

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
    const text = useText();

    useDocumentTitle(text('routes.login.title'));

    const { login } = useAuth();

    const handleLogin: TLogin = async ({ email, password }) => {
        try {
            await login(email, password);

            toast.success(text('routes.login.success.message'));
        } catch (error) {
            toast.error(text('routes.login.error.message'));
        }
    };

    return <LoginPage login={handleLogin} />;
};

export default Login;
