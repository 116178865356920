import React from 'react';
import { ForgotPasswordPage, type TRecoveryPassword } from 'components/pages';
import { useDocumentTitle } from 'react-document-metadata';
import { useNavigate } from 'react-router-dom';
import { useText } from 'react-text-toolkit';
import { toast } from 'react-toastify';

interface ForgotPasswordProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
    const navigate = useNavigate();

    const text = useText();

    useDocumentTitle(text('routes.forgotPassword.title'));

    const handleRecoveryPassword: TRecoveryPassword = async () => {
        try {
            toast.success(text('routes.forgotPassword.success.message'));

            setTimeout(() => navigate('/login'), 3000);
        } catch (error) {
            toast.error(text('routes.forgotPassword.error.message'));
        }
    };

    return <ForgotPasswordPage recoveryPassword={handleRecoveryPassword} />;
};

export default ForgotPassword;
