import ReactGA from 'react-ga';
import { GA_TRACKING_ID } from 'utilities/constants';

export const initGA = () => {
    const trackingId = GA_TRACKING_ID;

    if (trackingId) {
        ReactGA.initialize(trackingId);
    }
};

export default initGA;
