export const texts = {
    en: {
        common: {
            app: {
                name: 'TypedBootstrap'
            },
            buttons: {
                submit: 'Submit'
            }
        },
        components: {
            organisms: {
                cookieBanner: {
                    message: 'This website uses cookies to enhance the user experience.',
                    buttons: {
                        accept: 'I understand'
                    }
                },
                footer: {
                    copyright: '© 2024 Company, Inc'
                },
                loginForm: {
                    fields: {
                        email: {
                            label: 'Email',
                            validation: {
                                invalid: 'Email is not valid',
                                required: 'Email is required'
                            }
                        },
                        password: {
                            label: 'Password',
                            validation: {
                                required: 'Password is required'
                            }
                        }
                    },
                    buttons: {
                        login: 'Login'
                    }
                },
                passwordRecoveryForm: {
                    fields: {
                        email: {
                            label: 'Email',
                            validation: {
                                invalid: 'Email address is not valid',
                                required: 'Email is required'
                            }
                        }
                    }
                },
                registerForm: {
                    fields: {
                        username: {
                            label: 'Username',
                            validation: {
                                min: 'Username must be at least six characters',
                                required: 'Username is required'
                            }
                        },
                        email: {
                            label: 'Email',
                            validation: {
                                invalid: 'Email address is not valid',
                                required: 'Email is required'
                            }
                        },
                        password: {
                            label: 'Password',
                            validation: {
                                min: 'Password must be at least six characters',
                                required: 'Password is required'
                            }
                        },
                        confirmPassword: {
                            label: 'Confirm Password',
                            validation: {
                                oneOf: 'Passwords must match',
                                required: 'Confirm password is required'
                            }
                        }
                    },
                    buttons: {
                        register: 'Register'
                    }
                }
            },
            pages: {
                forgotPassword: {
                    title: 'Password Recovery',
                    message:
                        'Enter your email address and we will send you a link to reset your password',
                    links: {
                        login: 'Return to login'
                    }
                },
                home: {
                    title: 'A Bootstrap Starter Template',
                    message: 'A complete project boilerplate built with Bootstrap',
                    version: 'Bootstrap v5.2.3'
                },
                login: {
                    title: 'Welcome back!',
                    links: {
                        forgotPassword: 'Forgot your password?',
                        register: 'Need an account? Register'
                    }
                },
                noMatch: {
                    title: 'Not Found',
                    message: 'This requested URL was not found on this server.',
                    links: {
                        home: 'Return to Home'
                    }
                },
                register: {
                    title: 'Create Account',
                    links: {
                        login: 'Have an account? Login'
                    }
                },
                unauthorized: {
                    title: 'Unauthorized',
                    message: 'You do not have permission to view this page.',
                    links: {
                        home: 'Return to Home'
                    }
                }
            },
            templates: {}
        },
        routes: {
            forgotPassword: {
                title: 'Password Recovery',
                success: {
                    message: 'Password reset email sent! Redirecting to login...'
                },
                error: {
                    message: 'Failed to send password reset email. Please try again.'
                }
            },
            home: {
                title: 'Home'
            },
            login: {
                title: 'Login',
                success: {
                    message: 'Welcome back! You have successfully logged in.'
                },
                error: {
                    message: 'Login failed. Please check your credentials and try again.'
                }
            },
            noMatch: {
                title: 'Not Found'
            },
            register: {
                title: 'Register',
                success: {
                    message: 'Registration successful! Redirecting to login...'
                },
                error: {
                    message: 'Registration failed. Please try again.'
                }
            },
            unauthorized: {
                title: 'Unauthorized'
            }
        }
    },
    es: {
        common: {
            app: {
                name: 'TypedBootstrap'
            },
            buttons: {
                submit: 'Enviar'
            }
        },
        components: {
            organisms: {
                cookieBanner: {
                    message:
                        'Este sitio web utiliza cookies para mejorar la experiencia del usuario.',
                    buttons: {
                        accept: 'Entiendo'
                    }
                },
                footer: {
                    copyright: '© 2024 Empresa, Inc'
                },
                loginForm: {
                    fields: {
                        email: {
                            label: 'Correo Electrónico',
                            validation: {
                                invalid: 'El correo electrónico no es válido',
                                required: 'El correo electrónico es obligatorio'
                            }
                        },
                        password: {
                            label: 'Contraseña',
                            validation: {
                                required: 'La contraseña es obligatoria'
                            }
                        }
                    },
                    buttons: {
                        login: 'Iniciar Sesión'
                    }
                },
                passwordRecoveryForm: {
                    fields: {
                        email: {
                            label: 'Correo Electrónico',
                            validation: {
                                invalid: 'La dirección de correo electrónico no es válida',
                                required: 'El correo electrónico es obligatorio'
                            }
                        }
                    }
                },
                registerForm: {
                    fields: {
                        username: {
                            label: 'Nombre de Usuario',
                            validation: {
                                min: 'El nombre de usuario debe tener al menos seis caracteres',
                                required: 'El nombre de usuario es obligatorio'
                            }
                        },
                        email: {
                            label: 'Correo Electrónico',
                            validation: {
                                invalid: 'La dirección de correo electrónico no es válida',
                                required: 'El correo electrónico es obligatorio'
                            }
                        },
                        password: {
                            label: 'Contraseña',
                            validation: {
                                min: 'La contraseña debe tener al menos seis caracteres',
                                required: 'La contraseña es obligatoria'
                            }
                        },
                        confirmPassword: {
                            label: 'Confirmar Contraseña',
                            validation: {
                                oneOf: 'Las contraseñas deben coincidir',
                                required: 'Confirmar contraseña es obligatorio'
                            }
                        }
                    },
                    buttons: {
                        register: 'Registrarse'
                    }
                }
            },
            pages: {
                forgotPassword: {
                    title: 'Recuperación de Contraseña',
                    message:
                        'Ingrese su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña',
                    links: {
                        login: 'Volver a iniciar sesión'
                    }
                },
                home: {
                    title: 'Una plantilla de inicio de Bootstrap',
                    message: 'Un proyecto completo construido con Bootstrap',
                    version: 'Bootstrap v5.2.3'
                },
                login: {
                    title: '¡Bienvenido de nuevo!',
                    links: {
                        forgotPassword: '¿Olvidaste tu contraseña?',
                        register: '¿Necesitas una cuenta? Regístrate'
                    }
                },
                noMatch: {
                    title: 'No Encontrado',
                    message: 'La URL solicitada no se encontró en este servidor.',
                    links: {
                        home: 'Volver a Inicio'
                    }
                },
                register: {
                    title: 'Crear Cuenta',
                    links: {
                        login: '¿Tienes una cuenta? Iniciar Sesión'
                    }
                },
                unauthorized: {
                    title: 'No Autorizado',
                    message: 'No tienes permiso para ver esta página.',
                    links: {
                        home: 'Volver a Inicio'
                    }
                }
            },
            templates: {}
        },
        routes: {
            forgotPassword: {
                title: 'Recuperación de Contraseña',
                success: {
                    message:
                        '¡Correo de restablecimiento de contraseña enviado! Redirigiendo a iniciar sesión...'
                },
                error: {
                    message:
                        'Error al enviar el correo de restablecimiento de contraseña. Por favor, inténtelo de nuevo.'
                }
            },
            home: {
                title: 'Inicio'
            },
            login: {
                title: 'Iniciar Sesión',
                success: {
                    message: '¡Bienvenido de nuevo! Has iniciado sesión con éxito.'
                },
                error: {
                    message:
                        'Error al iniciar sesión. Por favor, verifica tus credenciales e inténtalo de nuevo.'
                }
            },
            noMatch: {
                title: 'No Encontrado'
            },
            register: {
                title: 'Registrarse',
                success: {
                    message: '¡Registro exitoso! Redirigiendo a iniciar sesión...'
                },
                error: {
                    message: 'Error en el registro. Por favor, inténtelo de nuevo.'
                }
            },
            unauthorized: {
                title: 'No Autorizado'
            }
        }
    }
};
