import React from 'react';
import { Button, Form, InputField, type FormHelpers } from '@typedbootstrap/ui-kit-pro-components';
import { useText } from 'react-text-toolkit';
import * as Yup from 'yup';
import LoginFormSchema from './LoginForm.schema';

export type TLoginFormValues = Yup.InferType<typeof LoginFormSchema>;

export type TLoginFormHelpers = FormHelpers<TLoginFormValues>;

export type TLoginFormSubmitHandler = (
    values: TLoginFormValues,
    helpers: TLoginFormHelpers
) => void;

export interface LoginFormProps {
    initialValues: TLoginFormValues;
    onSubmit: TLoginFormSubmitHandler;
}

const LoginForm: React.FC<LoginFormProps> = ({ initialValues, onSubmit }) => {
    const text = useText();

    return (
        <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={LoginFormSchema}>
            {({ isSubmitting }) => (
                <React.Fragment>
                    <InputField
                        type="email"
                        label={text('components.organisms.loginForm.fields.email.label')}
                        name="email"
                        size="lg"
                    />
                    <InputField
                        type="password"
                        label={text('components.organisms.loginForm.fields.password.label')}
                        name="password"
                        size="lg"
                    />
                    <Button type="submit" color="primary" disabled={isSubmitting} size="lg" block>
                        {text('components.organisms.loginForm.buttons.login')}
                    </Button>
                </React.Fragment>
            )}
        </Form>
    );
};

export default LoginForm;
