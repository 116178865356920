import React from 'react';
import { Button, Form, InputField, type FormHelpers } from '@typedbootstrap/ui-kit-pro-components';
import { useText } from 'react-text-toolkit';
import * as Yup from 'yup';
import RegisterFormSchema from './RegisterForm.schema';

export type TRegisterFormValues = Yup.InferType<typeof RegisterFormSchema>;

export type TRegisterFormHelpers = FormHelpers<TRegisterFormValues>;

export type TRegisterFormSubmitHandler = (
    values: TRegisterFormValues,
    helpers: TRegisterFormHelpers
) => void;

export interface RegisterFormProps {
    initialValues: TRegisterFormValues;
    onSubmit: TRegisterFormSubmitHandler;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ initialValues, onSubmit }) => {
    const text = useText();

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={RegisterFormSchema}>
            {({ isSubmitting }) => (
                <React.Fragment>
                    <InputField
                        type="text"
                        label={text('components.organisms.registerForm.fields.username.label')}
                        name="username"
                        size="lg"
                    />
                    <InputField
                        type="email"
                        label={text('components.organisms.registerForm.fields.email.label')}
                        name="email"
                        size="lg"
                    />
                    <InputField
                        type="password"
                        label={text('components.organisms.registerForm.fields.password.label')}
                        name="password"
                        size="lg"
                    />
                    <InputField
                        type="password"
                        label={text(
                            'components.organisms.registerForm.fields.confirmPassword.label'
                        )}
                        name="confirmPassword"
                        size="lg"
                    />
                    <Button type="submit" color="primary" disabled={isSubmitting} size="lg" block>
                        {text('components.organisms.registerForm.buttons.register')}
                    </Button>
                </React.Fragment>
            )}
        </Form>
    );
};

export default RegisterForm;
