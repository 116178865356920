import React from 'react';
import { TCurrentUser } from 'api/schemas';
import { AuthContext } from 'react-auth-toolkit';
import { PermissionProvider } from 'react-permission-toolkit';

export interface AuthProviderProps {
    children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [data, setData] = React.useState<TCurrentUser>();

    const login = (email: string, password: string) => {
        setData({ email, permissions: [] });
    };

    const register = (email: string, password: string) => {
        setData({ email, permissions: [] });
    };

    const logout = () => {
        setData(undefined);
    };

    return (
        <AuthContext.Provider value={{ data, login, logout, register }}>
            <PermissionProvider permissions={data?.permissions || []}>
                {children}
            </PermissionProvider>
        </AuthContext.Provider>
    );
};

export default AuthProvider;
