import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { logPageView } from 'utilities/helpers/analytics';
import { ForgotPassword, Home, Login, NoMatch, Protected, Register, Unauthorized } from './routes';

const Router: React.FC = () => {
    const location = useLocation();

    React.useEffect(() => {
        logPageView(location.pathname);
    }, [location]);

    return (
        <Routes>
            {/* Authenticated routes */}
            <Route path="/" element={<Protected />}>
                <Route index element={<Home />} />
            </Route>

            {/* Unauthenticated routes */}
            <Route path="/" element={<Protected requiresAuth={false} redirectTo="/" />}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
            </Route>

            <Route path="unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<NoMatch />} />
        </Routes>
    );
};

export default Router;
