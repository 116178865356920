import React from 'react';
import { Box, Container } from '@typedbootstrap/ui-kit-pro-components';
import { DefaultTemplate } from '@typedbootstrap/ui-kit-web-components';
import { Text } from 'react-text-toolkit';

export interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = () => (
    <DefaultTemplate>
        <Container>
            <Box className="text-center" mt="5">
                <h1>
                    <Text textKey="components.pages.home.title" />
                </h1>
                <p className="lead">
                    <Text textKey="components.pages.home.message" />
                </p>
                <p>
                    <Text textKey="components.pages.home.version" />
                </p>
            </Box>
        </Container>
    </DefaultTemplate>
);

export default HomePage;
