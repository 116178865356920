import ReactGA from 'react-ga';
import { GA_TRACKING_ID } from 'utilities/constants';

const logPageView = (path: string) => {
    const trackingId = GA_TRACKING_ID;

    if (trackingId) {
        ReactGA.set({ page: path });
        ReactGA.pageview(path);
    }
};

export default logPageView;
