import React from 'react';
import { CookieBanner } from '@typedbootstrap/ui-kit-web-components';
import { useSelector } from 'hooks';
import { useText } from 'react-text-toolkit';
import { ToastContainer } from 'react-toastify';
import { selectToastPosition } from 'store/settingsSlice';
import Router from './Router';

function App() {
    const text = useText();

    const toastPosition = useSelector(selectToastPosition);

    return (
        <React.Fragment>
            <Router />
            <CookieBanner
                acceptButtonText={text('components.organisms.cookieBanner.buttons.accept')}
                messageText={text('components.organisms.cookieBanner.message')}
            />
            <ToastContainer position={toastPosition} />
        </React.Fragment>
    );
}

export default App;
