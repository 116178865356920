import React from 'react';
import { Col, Container, Link, Row } from '@typedbootstrap/ui-kit-pro-components';
import { AuthenticationTemplate } from '@typedbootstrap/ui-kit-web-components';
import {
    RegisterForm,
    type TRegisterFormSubmitHandler,
    type TRegisterFormValues
} from 'components/organisms';
import { Text } from 'react-text-toolkit';

export type TRegisterArgs = {
    username: string;
    email: string;
    password: string;
};

export type TRegister = (args: TRegisterArgs) => Promise<void>;

export interface RegisterPageProps {
    register: TRegister;
}

const RegisterPage: React.FC<RegisterPageProps> = ({ register }) => {
    const initialValues: TRegisterFormValues = {
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
    };

    const onSubmit: TRegisterFormSubmitHandler = async (
        { username, email, password },
        { resetForm, setSubmitting }
    ) => {
        try {
            await register({
                username,
                email,
                password
            });

            resetForm();
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <AuthenticationTemplate>
            <Container>
                <Row>
                    <Col md="9" lg="8" className="mx-auto">
                        <h2 className="fw-light mb-4">
                            <Text textKey="components.pages.register.title" />
                        </h2>
                        <RegisterForm initialValues={initialValues} onSubmit={onSubmit} />
                        <Link className="d-block text-center mt-3 small" to="/login">
                            <Text textKey="components.pages.register.links.login" />
                        </Link>
                    </Col>
                </Row>
            </Container>
        </AuthenticationTemplate>
    );
};

export default RegisterPage;
