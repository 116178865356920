import { configureStore } from '@reduxjs/toolkit';
import { settingsReducer } from './settingsSlice';

const store = configureStore({
    reducer: {
        settings: settingsReducer
    }
});

export type TRootState = ReturnType<typeof store.getState>;

export type TAppDispatch = typeof store.dispatch;

export default store;
