import React from 'react';
import { NoMatchPage } from 'components/pages';
import { useDocumentTitle } from 'react-document-metadata';
import { useText } from 'react-text-toolkit';

interface NoMatchProps {}

const NoMatch: React.FC<NoMatchProps> = () => {
    const text = useText();

    useDocumentTitle(text('routes.noMatch.title'));

    return <NoMatchPage />;
};

export default NoMatch;
