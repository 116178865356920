import { getText } from 'react-text-toolkit';
import * as Yup from 'yup';

const LoginFormSchema = Yup.object().shape({
    email: Yup.string()
        .email(getText('components.organisms.loginForm.fields.email.validation.invalid'))
        .required(getText('components.organisms.loginForm.fields.email.validation.required')),
    password: Yup.string().required(
        getText('components.organisms.loginForm.fields.password.validation.required')
    )
});

export default LoginFormSchema;
