import { getText } from 'react-text-toolkit';
import * as Yup from 'yup';

const RegisterFormSchema = Yup.object().shape({
    username: Yup.string()
        .min(6, getText('components.organisms.registerForm.fields.username.validation.min'))
        .required(getText('components.organisms.registerForm.fields.username.validation.required')),
    email: Yup.string()
        .email(getText('components.organisms.registerForm.fields.email.validation.invalid'))
        .required(getText('components.organisms.registerForm.fields.email.validation.required')),
    password: Yup.string()
        .min(6, getText('components.organisms.registerForm.fields.password.validation.min'))
        .required(getText('components.organisms.registerForm.fields.password.validation.required')),
    confirmPassword: Yup.string()
        .oneOf(
            [Yup.ref('password'), ''],
            getText('components.organisms.registerForm.fields.confirmPassword.validation.oneOf')
        )
        .required(
            getText('components.organisms.registerForm.fields.confirmPassword.validation.required')
        )
});

export default RegisterFormSchema;
