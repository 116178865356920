import React from 'react';
import ReactDOM from 'react-dom/client';
import '@typedbootstrap/ui-kit-pro-styles/dist/css/styles.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './initializeTexts';
import { initGA } from 'utilities/helpers/analytics';
import App from './App';
import { AppProviders } from './contexts';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// If you want to enable Google Analytics tracking, set the environment
// variable `REACT_APP_GA_TRACKING_ID` to your Google Analytics tracking ID.
initGA();

root.render(
    <React.StrictMode>
        <AppProviders>
            <App />
        </AppProviders>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
