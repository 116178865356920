import React from 'react';
import { UnauthorizedPage } from 'components/pages';
import { useDocumentTitle } from 'react-document-metadata';
import { useText } from 'react-text-toolkit';

interface UnauthorizedProps {}

const Unauthorized: React.FC<UnauthorizedProps> = () => {
    const text = useText();

    useDocumentTitle(text('routes.unauthorized.title'));

    return <UnauthorizedPage />;
};

export default Unauthorized;
