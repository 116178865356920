import React from 'react';
import { RegisterPage, type TRegister } from 'components/pages';
import { useAuth } from 'hooks';
import { useDocumentTitle } from 'react-document-metadata';
import { withFeatureToggle } from 'react-feature-toggler';
import { useNavigate } from 'react-router-dom';
import { useText } from 'react-text-toolkit';
import { toast } from 'react-toastify';

interface RegisterProps {}

const Register: React.FC<RegisterProps> = () => {
    const text = useText();

    useDocumentTitle(text('routes.register.title'));

    const { register } = useAuth();

    const navigate = useNavigate();

    const handleRegister: TRegister = async ({ email, password }) => {
        try {
            await register(email, password);

            toast.success(text('routes.register.success.message'));

            setTimeout(() => navigate('/login'), 3000);
        } catch (error) {
            toast.error(text('routes.register.error.message'));
        }
    };

    return <RegisterPage register={handleRegister} />;
};

export default withFeatureToggle('enableRegister')(Register);
