import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { ToastPosition } from 'react-toastify';
import type { TRootState } from 'store';

type TSetting = boolean | string | number | object;

interface SettingsState {
    [index: string]: TSetting;
}

const initialState: SettingsState = {
    toastPosition: 'bottom-right'
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        changeSetting: (state, action: PayloadAction<{ name: string; value: TSetting }>) => {
            state[action.payload.name] = action.payload.value;
        },
        toggleSetting: (state, action: PayloadAction<string>) => {
            if (typeof state[action.payload] === 'boolean') {
                state[action.payload] = !state[action.payload];
            }
        }
    }
});

export const selectToastPosition = (state: TRootState): ToastPosition => {
    return state.settings.toastPosition as ToastPosition;
};

export const { actions: settingsActions, reducer: settingsReducer } = settingsSlice;
