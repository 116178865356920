import React from 'react';
import { Button, Form, InputField, type FormHelpers } from '@typedbootstrap/ui-kit-pro-components';
import { useText } from 'react-text-toolkit';
import * as Yup from 'yup';
import PasswordRecoveryFormSchema from './PasswordRecoveryForm.schema';

export type TPasswordRecoveryFormValues = Yup.InferType<typeof PasswordRecoveryFormSchema>;

export type TPasswordRecoveryFormHelpers = FormHelpers<TPasswordRecoveryFormValues>;

export type TPasswordRecoveryFormSubmitHandler = (
    values: TPasswordRecoveryFormValues,
    helpers: TPasswordRecoveryFormHelpers
) => void;

export interface PasswordRecoveryFormProps {
    initialValues: TPasswordRecoveryFormValues;
    onSubmit: TPasswordRecoveryFormSubmitHandler;
}

const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = ({ initialValues, onSubmit }) => {
    const text = useText();

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={PasswordRecoveryFormSchema}>
            {({ isSubmitting }) => (
                <React.Fragment>
                    <InputField
                        type="email"
                        label={text('components.organisms.passwordRecoveryForm.fields.email.label')}
                        name="email"
                        size="lg"
                    />
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                        {text('common.buttons.submit')}
                    </Button>
                </React.Fragment>
            )}
        </Form>
    );
};

export default PasswordRecoveryForm;
