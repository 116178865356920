import { useSelector, type TypedUseSelectorHook } from 'react-redux';
import type { TRootState } from 'store';

/**
 * Custom hook for typed useSelector function using react-redux.
 *
 * This hook provides access to the useSelector function with the specified type.
 *
 * @returns An instance of the useSelector hook with the specified type for the state.
 */
const useTypedSelector: TypedUseSelectorHook<TRootState> = useSelector;

export default useTypedSelector;
