import React from 'react';
import { HomePage } from 'components/pages';
import { withDefaultLayout } from 'layouts';
import { useDocumentTitle } from 'react-document-metadata';
import { useText } from 'react-text-toolkit';

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
    const text = useText();

    useDocumentTitle(text('routes.home.title'));

    return <HomePage />;
};

export default withDefaultLayout(Home);
