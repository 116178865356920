import { getText } from 'react-text-toolkit';
import * as Yup from 'yup';

const PasswordRecoveryFormSchema = Yup.object().shape({
    email: Yup.string()
        .email(getText('components.organisms.passwordRecoveryForm.fields.email.validation.invalid'))
        .required(
            getText('components.organisms.passwordRecoveryForm.fields.email.validation.required')
        )
});

export default PasswordRecoveryFormSchema;
