import React from 'react';
import { useAuth } from 'hooks';
import { CurrentUserProvider } from 'react-auth-toolkit';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface ProtectedProps {
    requiresAuth?: boolean;
    redirectTo?: string;
}

const Protected: React.FC<ProtectedProps> = ({ requiresAuth = true, redirectTo = '/login' }) => {
    const { data } = useAuth();

    const location = useLocation();

    const isAuthenticated: boolean = !!data;

    if (requiresAuth && !isAuthenticated) {
        return <Navigate to={redirectTo} state={{ redirectedFrom: location }} replace />;
    }

    if (!requiresAuth && isAuthenticated) {
        return <Navigate to={redirectTo} replace />;
    }

    if (requiresAuth) {
        return (
            <CurrentUserProvider
                fallback={<Navigate to="/login" state={{ redirectedFrom: location }} replace />}>
                <Outlet />
            </CurrentUserProvider>
        );
    }

    return <Outlet />;
};

export default Protected;
