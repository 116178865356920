import { TCurrentUser } from 'api/schemas';
import { useAuth } from 'react-auth-toolkit';

/**
 * Custom hook for typed authentication using react-auth-toolkit.
 *
 * This hook provides user authentication functionality, including
 * registration, login, and logout. It also allows access to user data.
 *
 * @returns An instance of the useAuth hook with the specified type for the user data.
 */
const useTypedAuth = () => useAuth<TCurrentUser>();

export default useTypedAuth;
