import React from 'react';
import { Footer, Topnav } from '@typedbootstrap/ui-kit-web-components';
import { useText } from 'react-text-toolkit';

export interface DefaultLayoutProps {
    children: React.ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
    const text = useText();

    return (
        <React.Fragment>
            <Topnav
                brand={{
                    text: text('common.app.name'),
                    image: {
                        alt: 'Logo',
                        src: 'https://avatars.githubusercontent.com/u/93922952',
                        height: 28,
                        width: 28
                    },
                    href: '/'
                }}
                items={[
                    {
                        label: 'Home',
                        href: '/'
                    }
                ]}
            />
            <main>{children}</main>
            <Footer
                brand={{
                    text: text('common.app.name'),
                    image: {
                        alt: 'Logo',
                        src: 'https://avatars.githubusercontent.com/u/93922952',
                        height: 28,
                        width: 28
                    },
                    href: '/'
                }}
                links={[
                    {
                        label: 'Home',
                        href: '/'
                    }
                ]}
                text={text('components.organisms.footer.copyright')}
            />
        </React.Fragment>
    );
};

export default DefaultLayout;
