import React from 'react';
import { Col, Container, Link, Row } from '@typedbootstrap/ui-kit-pro-components';
import { AuthenticationTemplate } from '@typedbootstrap/ui-kit-web-components';
import {
    LoginForm,
    type TLoginFormSubmitHandler,
    type TLoginFormValues
} from 'components/organisms';
import { useFeatureToggle } from 'react-feature-toggler';
import { Text } from 'react-text-toolkit';

export type TLoginArgs = {
    email: string;
    password: string;
};

export type TLogin = (args: TLoginArgs) => Promise<void>;

export interface LoginPageProps {
    login: TLogin;
}

const LoginPage: React.FC<LoginPageProps> = ({ login }) => {
    const { isFeatureEnabled } = useFeatureToggle();

    const registerEnabled = isFeatureEnabled('enableRegister');

    const initialValues: TLoginFormValues = {
        email: '',
        password: ''
    };

    const onSubmit: TLoginFormSubmitHandler = async (
        { email, password },
        { setErrors, setSubmitting }
    ) => {
        try {
            await login({ email, password });

            setSubmitting(false);
        } catch (error) {
            setErrors({ email: 'Invalid email or password' });
            setSubmitting(false);
        }
    };

    return (
        <AuthenticationTemplate>
            <Container>
                <Row>
                    <Col md="9" lg="8" className="mx-auto">
                        <h2 className="fw-light mb-4">
                            <Text textKey="components.pages.login.title" />
                        </h2>
                        <LoginForm initialValues={initialValues} onSubmit={onSubmit} />
                        <Link className="d-block text-center mt-3 mb-3 small" to="/forgot-password">
                            <Text textKey="components.pages.login.links.forgotPassword" />
                        </Link>
                        {registerEnabled && (
                            <Link className="d-block text-center small" to="/register">
                                <Text textKey="components.pages.login.links.register" />
                            </Link>
                        )}
                    </Col>
                </Row>
            </Container>
        </AuthenticationTemplate>
    );
};

export default LoginPage;
